(function ($) {
  $(window).load(function () {
    $('.owl-carousel:not(.slider)').owlCarousel({
      // slider settings
      autoplay: true,
      autoplayHoverPause: true,
      loop: true,
      items: 1,

      // slide speed
      autoplayTimeout: 7000,
      smartSpeed: 500,

      // animation
      animateIn: 'fadeIn',
      animateOut: 'fadeOut',

      // navigation
      nav: true,
      navText: ['<i class=\'fas fa-arrow-left\'></i>', '<i class=\'fas fa-arrow-right\'></i>'],

      dots: true,
      dotsEach: true,

      // drag options
      mouseDrag: false
    })

    $countItems = $('.slider.home-contentblock-slider  .item').length;
    if ($countItems > 2) {
      $(".slider.home-contentblock-slider").owlCarousel({
        // slider settings
        autoplay: true,
        autoplayHoverPause: true,
        loop: true,
        items: 1,
        margin: 15,

        // slide speed
        autoplayTimeout: 7000,
        smartSpeed: 500,

        // navigation
        nav: true,
        navContainer: false,
        navText: ['<i class=\'fas fa-arrow-left\'></i>', '<i class=\'fas fa-arrow-right\'></i>'],

        dots: true,
        dotsEach: true,

        // drag options
        mouseDrag: true,

        // responsive
        responsive: {
          0: {
            items: 1
          },
          576: {
            items: 2
          },
          768: {
            items: 2
          },
          992: {
            items: 3
          },
          1200: {
            items: 3
          }
        }
      });
    }

    $(".slider:not(.home-contentblock-slider):not(.show_detail_other_shows):not(.owl-brands-slider):not(.owl-cast-slider):not(.owl-cast-thewiz-slider):not(.owl-news-slider):not(.owl-reviews-thewiz-slider)").owlCarousel({
      // slider settings
      autoplay: true,
      autoplayHoverPause: true,
      loop: false,
      items: 1,
      margin: 15,

      // slide speed
      autoplayTimeout: 7000,
      smartSpeed: 500,

      // navigation
      nav: true,
      navContainer: false,
      navText: ['<i class=\'fas fa-arrow-left\'></i>', '<i class=\'fas fa-arrow-right\'></i>'],

      dots: true,
      dotsEach: true,

      // drag options
      mouseDrag: true,

      // responsive
      responsive: {
        0: {
          items: 1
        },
        576: {
          items: 2
        },
        768: {
          items: 2
        },
        992: {
          items: 3
        },
        1200: {
          items: 5
        }
      }
    });

    $(".slider.show_detail_other_shows.owl-carousel").owlCarousel({
      // slider settings
      autoplay: true,
      autoplayHoverPause: true,
      loop: true,
      items: 1,
      margin: 15,

      // slide speed
      autoplayTimeout: 7000,
      smartSpeed: 500,

      // navigation
      nav: true,
      navContainer: false,
      navText: ['<i class=\'fas fa-arrow-left\'></i>', '<i class=\'fas fa-arrow-right\'></i>'],

      dots: true,
      dotsEach: true,

      // drag options
      mouseDrag: true,

      // responsive
      responsive: {
        0: {
          items: 1
        },
        576: {
          items: 2
        },
        768: {
          items: 2
        },
        992: {
          items: 3
        },
        1200: {
          items: 5
        }
      }
    });

    // owl-brands-slider
    $('.owl-brands-slider').owlCarousel({
      items: 1,
      margin: 15,
      loop: true,
      lazyLoad: true,
      stagePadding: 10,

      // autoplay
      autoplay: true,
      autoplayTimeout: 4000,

      // speed
      smartSpeed: 6000,
      slideTransition: 'linear',

      // navigation
      nav: true,
      navText: ['<i class=\'fas fa-chevron-left\'></i>', '<i class=\'fas fa-chevron-right\'></i>'],
      dots: false,

      // drag
      mouseDrag: true,

      // responsive
      responsive: {
        0: {
          items: 2
        },
        576: {
          items: 3
        },
        768: {
          items: 4
        },
        992: {
          items: 4
        },
        1200: {
          items: 5,
          margin: 30
        }
      }
    });

    // owl-cast-slider
    $('.owl-cast-slider').owlCarousel({
      items: 1,
      margin: 15,
      loop: true,

      // autoplay
      autoplay: false,
      autoplayTimeout: 7000,

      // speed
      smartSpeed: 500,

      // navigation
      nav: false,
      dots: true,

      // drag
      mouseDrag: true,

      // responsive
      responsive: {
        0: {
          items: 2
        },
        576: {
          items: 3
        },
        768: {
          items: 4
        },
        992: {
          items: 4
        },
        1200: {
          items: 5,
          margin: 30
        }
      }
    });

    // owl-cast-thewiz-slider
    $('.owl-cast-thewiz-slider').owlCarousel({
      items: 1,
      margin: 0,
      loop: false,
      autoWidth: true,

      // autoplay
      autoplay: false,
      autoplayTimeout: 7000,

      // speed
      smartSpeed: 500,

      // navigation
      nav: false,
      dots: true,

      // drag
      mouseDrag: true,

      // responsive
      responsive: {
        0: {
          items: 1,
          center: true
        },
        576: {
          items: 2,
          center: true
        },
        768: {
          items: 2
        },
        992: {
          items: 3
        },
        1200: {
          items: 4
        }
      }
    });

    // owl-reviews-thewiz-slider
    $('.owl-reviews-thewiz-slider').owlCarousel({
      items: 1,
      margin: 15,
      loop: true,

      // autoplay
      autoplay: false,
      autoplayTimeout: 7000,

      // speed
      smartSpeed: 500,

      // navigation
      nav: false,
      dots: true,

      // drag
      mouseDrag: true,

      // responsive
      responsive: {
        0: {
          items: 1
        },
        576: {
          items: 2
        },
        768: {
          items: 2
        },
        992: {
          items: 2
        },
        1200: {
          items: 3,
          margin: 30
        }
      }
    });

    // owl-news-slider
    $('.owl-news-slider').owlCarousel({
      items: 1,
      margin: 15,
      loop: false,

      // autoplay
      autoplay: false,
      autoplayTimeout: 7000,

      // speed
      smartSpeed: 500,

      // navigation
      nav: false,
      dots: true,

      // drag
      mouseDrag: true,

      // responsive
      responsive: {
        0: {
          items: 1
        },
        576: {
          items: 2
        },
        768: {
          items: 2
        },
        992: {
          items: 3,
        },
        1200: {
          items: 3,
          margin: 50
        }
      }
    });

  });

  $(document).on('click', '#vacancy-filter-list a', function (e) {
    e.preventDefault();
    e.stopPropagation();

    $('#vacancy-filter-list li').removeClass('current');
    $(this).parents('li').first().addClass('current');

    var categoryId = $(this).data('categoryId');
    $('.vacancy_overview [data-category]').hide();
    $('.vacancy_overview [data-category="' + categoryId + '"]').slideDown();
  });

  $(document).ready(function () {
    var windowWidth = $(window).width();
    $(window).scroll(function () {
      if ($('body').hasClass('home')) {
        if ($(window).scrollTop() > $('.eyecatcher').outerHeight()) {
          $('body').addClass('sticky');
          $('.header').addClass('sticky');
        } else {
          $('body').removeClass('sticky');
          $('.header').removeClass('sticky');
        }
      } else {
        if ($(window).scrollTop() > 0) {
          $('body').addClass('sticky');
          $('.header').addClass('sticky');
        } else {
          $('body').removeClass('sticky');
          $('.header').removeClass('sticky');
        }
      }
    });

    $('a[data-fancybox]').fancybox({
      buttons: [
        //"zoom",
        //"share",
        //"slideShow",
        //"fullScreen",
        //"download",
        "thumbs",
        "close"
      ]
    });
  });

  $('.js-3w-scroll-to-content').on('click', function (event) {
    event.preventDefault();
    var identifier = $(this).attr('data-target');
    var $element = $(identifier);

    if ($element) {
      $('html, body').animate({
        scrollTop: $element.offset().top
      }, 500);
    }
  });

  $('.js-3w-close-highlight').on('click', function (event) {
    event.preventDefault();
    $(this).parent().parent().hide('slow', function () {
      $target.remove();
    });
  });

  // Search icon click
  $(document).on('click', '.page_block.searchbox .search-icon', function () {
    $('.page_block.searchbox .search-box-form').slideToggle("fast");
    $('.page_block.searchbox ').toggleClass('active');
  });

  const $scrollElements = $([document.documentElement, document.body]);

  $(document).on('click', '[data-scroll-to]', function (e) {
    e.preventDefault();
    e.stopPropagation();

    $scrollElements.animate({
      scrollTop: $($(this).data('scrollTo')).offset().top
    }, 1000);
  });

  $(document).on('click', '.js-show-extra-events', function (e) {
    $('.ntk-events.extra-events').show();
    $(this).hide();
  });
})(jQuery);
